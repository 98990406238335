import projects from "../utils/projects";
function ProjectCatalogues() {
  return (
    <>
      <div className="cont">
        <h1 id="ph">Creations</h1>
        <div id="projects">
          {projects.map((project, index) => (
            <Project
              key={index}
              title={project.title}
              des={project.des}
              image={project.pic}
            />
          ))}
        </div>
      </div>
    </>
  );
}

const Project = ({ title, des, image }) => {
  return (
    <div className="project">
      <img
        src={"https://kazmisyed.com/public/images/" + image}
        fetchpriority="high"
        alt={title + " mockup"}
        loading="lazy"
      />
      <div className="words">
        <h1>{title}</h1>
        <h2>{des}</h2>
      </div>
    </div>
  );
};

export default ProjectCatalogues;
