export default [
  {
    pic: "project-detego.webp",
    title: "Google Classroom Clone",
    des: "ExpressJS + Pug + MongoDB",
  },
  {
    pic: "/project-manage.webp",
    title:
      "TypeWriter + Auto-Incrementor + Scroll-0n/Scroll-over Animation from Scratch",
    des: "ReactJS Hooks",
  },
  {
    pic: "project-albab.webp",
    title: "Albab-Legacy App Modernization",
    des: "React Native",
  },
  {
    pic: "project-roomz.webp",
    title: "Roomz Arabia",
    des: "React Native",
  },
  {
    pic: "project-sahan.webp",
    title: "Sahan Trip",
    des: "React Native",
  },
  {
    pic: "project-charterCenter.webp",
    title: "Charter-Center",
    des: "React Native",
  },
  {
    pic: "project-teamPage.webp",
    title: "AskMsAbbas-Team Page",
    des: "HTML/CSS",
  },
  {
    pic: "project-staticPages.webp",
    title: "Screens (Landing/Error/Login)",
    des: "ReactJS + HTML/CSS/JS ",
  },
  {
    pic: "project-bankingSystem.webp",
    title: "Banking System",
    des: "C++ (Functional Programming)",
  },
  {
    pic: "project-cabBooking.webp",
    title: "Cab Booking System",
    des: "C++ (OOP)",
  },
  {
    pic: "project-rock.webp",
    title: "Rock Paper Scissor",
    des: "HTML/CSS",
  },
  {
    pic: "project-scraper.webp",
    title: "Web-Scraper",
    des: "Python",
  },
];
